<script>

import {defineComponent} from "vue";
import FileViewer from "../../../components/elements/file-viewer.vue";

export default defineComponent({
    components: {FileViewer}
})
</script>

<template>
    <file-viewer :path="$route.params.path.join('/')"></file-viewer>
</template>

<style scoped>

</style>
